@import url(https://fonts.googleapis.com/css?family=Sacramento);
@import url(https://fonts.googleapis.com/css?family=Roboto:600,400,300);

// Background

$background-img-file: 'pattern_bg_sym.png';
//$background-img-file: 'symphony_bright_contrasted.png';
//$background-img-file: 'symphony_50_percent_opacity.png';
$background-img: '../images/' + $background-img-file;
$background-imgsize: 198px;

// Colors
$primary-color:   #3FA9F5; // Blue
$secondary-color: #fff;    // White
$tertiary-color:  #000;    // Black
$highlight-color: #ED1E79; // Pink
$primary-color-trans: rgba($primary-color, .3);
$secondary-color-trans: rgba($secondary-color, 1);

// Grid
//$max-width:         420px !global; // overriden when medium + large
$default-feature:         min-width !global;
$small-min-width:         540px;
$medium-min-width:        768px;
$medium-container-width:  740px;

$block-padding:  16px;

// Font
$main-font-family:        'Roboto', Verdana, Geneva, sans-serif;
$main-font-size:          16px;
$main-font-line-height:   1.2;
$main-font-color:         $tertiary-color;
$main-font-weight:        400;
$main-font-weight-light:  300;
$main-font-weight-bold:   600;

$main-font-selection-background-color:  $highlight-color;
$main-font-selection-color:             $secondary-color;

$title-font-family:   	'Sacramento', "Brush Script MT", cursive;
$title-font-color:    	$primary-color;
$title-font-weight:   	400;
$main-font-line-height: 1.3;

$title-h1-font-size:        32px;
$title-h1-small-font-size: $title-h1-font-size*1.5;
$title-h1-medium-font-size: $title-h1-font-size*2;
$title-h1-margin:           $title-h1-font-size*3 0 $title-h1-font-size*(2/3);

$title-h2-font-size:        32px;

$title-h3-font-size:        28px;//24px;

$text-container-padding:    0 $block-padding $block-padding/2;

// Nav
$header-border-size:        1px;
$nav-link-padding-top:      20px;
$nav-link-padding-sides:    8px;
$nav-link-padding-bottom:   16px;
$nav-link-padding:          $nav-link-padding-top $nav-link-padding-sides $nav-link-padding-bottom;

// Links
$link-active-color:       $primary-color;
$link-hover-color:        $link-active-color;
