#pl-footer {
  // margin-bottom: $block-padding;

  abbr {
    text-decoration: none;
  }

  address,
  .pl-contact-details {
    font-style: normal;
    margin-bottom: $block-padding/2;
  }

  footer {
    text-align: center;
    font-size:  $main-font-size*3/4;
    padding: 0 $block-padding;
  }
}
