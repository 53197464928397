#pl-site-header {
  /*& > .container {
    //background-color:   $secondary-color;
    //border-bottom:      $header-border-size solid $primary-color;
    overflow:           auto;
    position: fixed;
  }*/
  nav.nav-primary {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    @media print {
      display: none;
    }
  }

  .nav-header {
    @include media($medium) {
      display: none;
    }
    button {
      border:             none;
      background-color:   $secondary-color;
      color:              $primary-color;
      font-family:        $main-font-family;
      font-size:          $main-font-size;
      padding:            $nav-link-padding;
      width:              100%;
      text-align:         center;
      cursor:             pointer;
    }
  }
}
#navbar {
  @include media($medium) { /* Necessary? */
    position: inherit;
    display: block;
  }

  &.js {
    display:            block;
    position:           absolute;
    width:              100%;
    //height:             100%;
    //left:               -100%;
    //top:0;
    //top:                $nav-link-padding-top+$nav-link-padding-bottom+round($main-font-size*$main-font-line-height)+$header-border-size;
    opacity:            0;
    
    &.shown {
      opacity:  1;
      left:      0%;
      background-color:   $secondary-color;
    }

    @include transition-property(left, opacity, background-color, height);
    @include transition-duration(0.5s);
    @include transition-timing-function(easeOutCubic);
    @include transition-delay(0s);

    @include media($medium) {
      &,
      &.shown {
        background-color: transparent;
        overflow:         auto;
        display:          block;
        //position:         fixed;//inherit;
        //top: 0;
        left: 0;
        height: auto;
        opacity:          1;
      }

      &.scrolled,
      &:hover {
        background-color: $secondary-color;
      }

      @include transition-property(background-color);
      @include transition-duration(0.5s);
      @include transition-timing-function(easeOutCubic);
      @include transition-delay(0s);
    }
    .menu-main-menu-container {
      width:    auto;
      margin:   auto;
    }
  }
  
  & > ul {
    @include media($medium) {
      &:first-child {
        float: left;
      }
      &:last-child {
        float: right;
      }
    }

    list-style-type:  none;
    padding:          0;
    margin:           0 25px;
    text-align: center;
    
    &.pl-ls {
      li {
        display: inline;
      }
      li:not(:last-child) {
        a {
          padding-right: 5px;
        }
        &::after {
          content: " - ";
        }
      }
      li:not(:first-child) a {
        padding-left: 5px;
      }
    }

    li {
      @include media($medium) {
        display: inline;
      }

      a {
        display:          inline-block;
        padding:          $nav-link-padding;
        text-decoration:  none;
        //border-bottom: 2px solid transparent;
        //text-shadow: 0px 2px 2px 0px $secondary-color;

        &:link,
        &:visited {
          color: $main-font-color;
        }

        &:hover,
        &:active {
          color:          $link-hover-color;
          //border-color:   $link-hover-color;
          //text-shadow: 1px 1px 2px $highlight-color;
        }

        @include transition-property(color, text-shadow, border-color);
        @include transition-duration(0.25s);
        @include transition-timing-function(easeOutCubic);
        @include transition-delay(0s);

      }

      &.active a {
        color: $link-active-color;
      }
      &.menu-item-language-current a {
        color: tint($main-font-color, 60%);
      }
    }
  }
}

h1.site-title {
  font-family:  $title-font-family;
  color:        $primary-color;
  text-align:   center;
  font-size:    $title-h1-font-size;
  font-weight:  $title-font-weight;
  margin:       $title-h1-margin;
  text-shadow:  0px 0px 3px rgba(255, 255, 255, 1);
  
  @include media($small) {
    font-size: $title-h1-small-font-size;
  }
  @include media($medium) {
    font-size:  $title-h1-medium-font-size;
  }

  @media print {
    margin-top: 0;
  }
}

#pl-ii > div {
  background-color: $highlight-color;
  color:            $secondary-color;

  *::selection {
    background-color:   $main-font-selection-color;
    color:              $main-font-selection-background-color;
  }
  
  header {
    overflow: auto;
    margin-bottom: $block-padding; 
  }

  h2,
  .pl-date {
    display:  inline;
    color:    $secondary-color;
  }
  
  .pl-date {
    float:        right;
    font-family:  $title-font-family;
    font-weight:  $title-font-weight;
    font-size:    $title-h3-font-size*(2/3);
    &::before { // Put the smaller sized date on the same line as the title
      content:    '\00a0 ';
      font-size:  $title-h2-font-size;
    }
  }

  p {
    margin: 0;
  }
}