html, body {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

html,
body {
  margin: 0;
  //padding: 0;

  font-family:  $main-font-family;
  font-size:    $main-font-size;
  line-height:  $main-font-line-height;
}

body {
  @include background(url($background-img) top center / 198px 198px repeat);
  background-attachment: fixed;
}

*::selection {
    background-color:   $main-font-selection-background-color;
    color:              $main-font-selection-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family:  $title-font-family;
  font-weight:  $title-font-weight;
  color:        $title-font-color;

  margin:       0;
}

h2 {
  font-size:  $title-h2-font-size;
  //text-shadow: 0 1px 1px rgba(0,0,0,0.1);
}

h3 {
  font-size:  $title-h3-font-size;
}

h4 {
  font-family:  $main-font-family;
  font-size:    $main-font-size;
  font-weight:  $main-font-weight-bold;
  color:        $main-font-color;
}

.text-container {
  padding: $text-container-padding;
}

body > *:not(header) {
  padding: 0 $block-padding;
}

article:not(.pl-banner) > div,
article > header,
article > section,
article.pl-banner,
#pl-footer > div {
  padding: $block-padding;
  background-color: $secondary-color;
  //@include linear-gradient(340deg, rgba(255,255,255,1) 0%, rgba(253,253,253,1) 70%, rgba(252,252,252,1) 100%);
  margin: $block-padding 0;
  box-shadow: 0px 4px 4px -3px rgba(0,0,0,0.1);

  &:hover {
    //box-shadow: 0px 4px 2px -3px rgba(0,0,0,0.2);
  }

  @include media($medium) {
    width:    $medium-container-width;
    margin:   $block-padding auto;
  }

  @include transition-property(all);
  @include transition-duration(0.25s);
  @include transition-timing-function(easeOutCubic);
  @include transition-delay(0s);
}


dl {
  margin: 0;
}
dt {
  float: left;
  font-weight: 600;
}
dt:after {
  content: "\00a0 :\00a0 ";
}
a {
  @include transition-property(color);
  @include transition-duration(0.25s);
  @include transition-timing-function(easeOutCubic);
  @include transition-delay(0s);
}
a:link,
a:visited {
  color: $highlight-color;
  text-decoration: none;
}
a:hover,
a:active {
  color: $primary-color;
}

@include media($medium) {
  .pl-golden {
    overflow: auto;
    
    & > *:first-child {
      padding-right: $block-padding - 1;
    }
  }
  .pl-golden-sm {
    width: 38.197%;
    float: left;
  }
  .pl-golden-lg {
    width: 61.803%;
    float: left;
  }
}

table {
  width: 100%;
  text-align: center;
}
