article ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 $block-padding/2 $block-padding/2;
    
    .pl-re-description {
      font-weight: 300;
    }
    a:link,
    a:visited {
      margin-left: $block-padding;
    }
  }
}



.flexslider {
  margin: 0;
  border: 0;

  .flex-direction-nav a {
    &:before {
      //font-family: $main-font-family;
      //content: '<';
    }

    &.flex-next:before {
      //content: '>';
    }
  }
}
#pl-carousel {
  margin: 0;
  .slides li {
    width: auto;
    //margin: 0 2px;
    border-top: 2px solid transparent;
    opacity: 0.7;

    &:hover,
    &.flex-active-slide {
      border-color: $highlight-color;
      opacity: 1;
    }

    @include transition-property(opacity, border-color);
    @include transition-duration(0.7s);
    @include transition-timing-function(easeOutCubic);
    @include transition-delay(0s);
  }
}
#pl-slider {
  .slides {
    li {
      position: relative; //Makes the label appear after a delay

      p {
        position: absolute;
        bottom: 0;
        
        margin: $block-padding/2 0;
        padding: $block-padding/2;
        width: 100%;//438px; /* Nicht gut, nein! */
        background-color: rgba($secondary-color, 0.7);
        text-align: center;
        font-weight: $main-font-weight-light;
        opacity: 0;

        @include transition-property(opacity, border-color, bottom);
        @include transition-duration(0.7s);
        @include transition-timing-function(easeOutCubic);
        @include transition-delay(0s);
      }

      &.flex-active-slide {
        p {
          opacity: 1;
        }
      }
    }
  }
}

#pl-gmap {
  height: 300px;
}
#pl-gmap-iw {
  address {
    font-style: normal;
  }
  abbr {
    text-decoration: none;
  }
}
#pl-gmap-legend {
  background: white;
  padding: $block-padding;
}
